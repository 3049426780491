import React, { useState } from 'react';
import { Link } from "react-router-dom";
import playStorelogo from '../../assets/playstore_icon.png'
import applelogo from '../../assets/apple_icon.png';
import chalobharatlogo from '../../assets/chalobharatdekhe.png';
import getStarted from '../../assets/get-started.png';
import searchDestination from '../../assets/search-destination.png';
import youAreAt from '../../assets/you-are-at.png';
import discoverNow from '../../assets/discover-now.png';
import exploreIndia from '../../assets/explore-india.png';
import tapToExplore from '../../assets/tap-to-explore.png';
import readAloud from '../../assets/read-aloud.png';
import nearbyPlaces from '../../assets/nearby-places.png';
import importantDistricts from '../../assets/important-districts.png';
import starred from '../../assets/starred.png';
import blogs from '../../assets/blogs.png';
import '../../App.css';
import Info from '../../Info';

const UserManual = () => {
    const openPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.chalobharatdekhen.app', '_blank');
    };

    const openAppStore = () => {
        window.open('https://apps.apple.com/in/app/bharat-dekhen/id6478861610', '_blank');
    };
    return (
        <>
            <div className='container'>
                <div className='logoMainView'>
                    <Link to="/">
                        <img src={chalobharatlogo} className="App-logo1" alt="logo" />
                    </Link>
                    <div>
                        <div className='appView'>
                            <p>Click to <br></br>download the app</p>
                        </div>

                        <div className="image-row">
                            <div className="image-container" onClick={openAppStore}>
                                <img src={applelogo} className="Android-logo" alt="Image 1" />
                            </div>
                            <div className="image-container" onClick={openPlayStore}>
                                <img src={playStorelogo} className="Android-logo" alt="Image 2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='pageHeading'>
                    <h1>User Manual</h1>
                    <h2>Welcome to Chalo Bharat Dekhen</h2>
                    <p>Effective Date: 10.05.2024</p>
                </div>
            </div>

            <div className='container'>
                <ol>
                    <li>
                        <strong>Introduction</strong>
                        <p>
                            Welcome to Chalo Bharat Dekhen, your ultimate travel information companion. This user manual will guide you through the features and functionality of our mobile application.
                        </p>
                    </li>

                    <li>
                        <strong>Getting Started</strong>
                        <ol>
                            <li>
                                <strong>Click on the "Let's get started" button to begin your dream Journey</strong><br></br>
                                <img src={getStarted} width={350} />
                            </li>

                        </ol>
                    </li>

                    <li>
                        <strong>Home Screen</strong>
                        <ol>
                            <li>
                                <strong>Search Your Destination</strong>
                                <ul>
                                    <li>
                                        <p>
                                            Use the search bar at the top to find specific places, cities, monuments, or districts.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Use the voice search feature to look up places or above information
                                            hands-free.
                                        </p>
                                    </li>
                                </ul>
                                <img src={searchDestination} width={350} />
                            </li>
                            <li>
                                <strong>
                                    You Are At
                                </strong>
                                <ul>
                                    <li>
                                        <p>
                                            Click to allow the app to access your location to track where you are
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Click again to go to the district page
                                        </p>
                                    </li>
                                </ul>
                                <img src={youAreAt} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Discover Now
                                </strong>
                                <ul>
                                    <li>
                                        <p>
                                            On the home screen, you'll find a list of 12 broad categories such as Religious
                                            Places, Historical Places, UNESCO Heritage Site, etc.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tap a category to explore related subcategories
                                        </p>
                                    </li>
                                </ul>
                                <img src={discoverNow} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Explore India Like Never Before
                                </strong>
                                <ul>
                                    <li>
                                        <p>
                                            Tap to know the latest and trending Holiday Destinations
                                        </p>
                                    </li>
                                </ul>
                                <img src={exploreIndia} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Tap Anywhere You Want To Explore
                                </strong>
                                <ul>
                                    <li>
                                        <p>
                                            The Indian map on the home screen can be zoomed in or out for better user
                                            experience.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tap on any state or union territory to view the list of districts in that region.
                                        </p>
                                    </li>
                                </ul>
                                <img src={tapToExplore} width={350} />
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Other Features</strong>
                        <ol>
                            <li>
                                <strong>
                                    Read Aloud
                                </strong>
                                <ul>
                                    <li>
                                        <p>Enjoy the convenience of having the app read aloud information about your
                                            selected destinations.</p>
                                    </li>
                                </ul>
                                <img src={readAloud} width={350} />
                            </li>
                            <li>
                                <strong>
                                    See Nearby Places:
                                </strong>
                                <ul>
                                    <li>
                                        <p>Get recommendations for nearby attractions and sites based on your current
                                            location or selected destinations.</p>
                                    </li>
                                    <li>
                                        <p>Discover hidden gems and must-see spots near places you plan to visit.</p>
                                    </li>
                                </ul>
                                <img src={nearbyPlaces} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Important Districts:
                                </strong>
                                <ul>
                                    <li>
                                        <p>Bullet points indicate key districts</p>
                                    </li>
                                </ul>
                                <img src={importantDistricts} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Starred
                                </strong>
                                <ul>
                                    <li>
                                        <p>Download district data by clicking on the Star icon about destinations, attractions,
                                            and maps for offline use.</p>
                                    </li>
                                    <li>
                                        <p>Access essential travel data without internet connection by clicking on the Starred
                                            Option in the left side panel</p>
                                    </li>
                                </ul>
                                <img src={starred} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Blogs:
                                </strong>
                                <ul>
                                    <li>
                                        <p>Read about the latest Travel Blogs from famous people in this section</p>
                                    </li>
                                </ul>
                                <img src={blogs} width={350} />
                            </li>
                            <li>
                                <strong>
                                    Feedback and Support:
                                </strong>
                                <ul>
                                    <li>
                                        <p>We value your feedback! Please share your thoughts, suggestions, or report any
                                            issues you encounter while using our app.</p>
                                    </li>
                                    <li>
                                        <p>Contact our support team for assistance or inquiries.</p>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Viewing Place Information</strong>
                        <ul>
                            <li>
                                <p>When you select a category, subcategory, or search option, you'll view the entire
                                    district data as follows:</p>
                                <ul>
                                    <li>
                                        <p>
                                            Religious place/ Historical Place/UNESCO, etc.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Optional Sights
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            History of the district
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Nearest Railway Station
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Nearest Airport
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Main Hotels
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Car Rentals
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Famous Food
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Famous Restaurants
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            District Website
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Best Shopping Products
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            District map
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            State Map
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Nearby Places
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Best Festivals
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            {/* <li><p>These documents outline your rights and responsibilities as a user.</p></li> */}
                        </ul>
                    </li>
                    <li>
                        <strong>Help and Support</strong>
                        <p>For any questions or issues, contact us at <a href="mailto:support@chalobhartdekhen.com">support@chalobhartdekhen.com</a> or the Contact Us option in left side panel of the Application.</p>
                    </li>
                    <li>
                        <strong>Legal and Privacy</strong>
                        <ul>
                            <li>
                                <p>Review the app's Terms and Conditions and Privacy Policy in the left side panel of
                                    App</p>
                            </li>
                            <li><p>These documents outline your rights and responsibilities as a user.</p></li>
                        </ul>
                    </li>
                </ol>
                <p>
                    Remember to explore, plan, and discover amazing places with the Chalo Bharat Dekhen mobile
                    application. If you have any further query or need assistance, please reach out to our support
                    team. Enjoy your travels and have a fantastic experience with our app!
                </p>
            </div>

            <Info />
        </>
    );
}

export default UserManual;