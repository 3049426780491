import React, { useState } from 'react';
import { Link } from "react-router-dom";
import playStorelogo from '../../assets/playstore_icon.png'
import applelogo from '../../assets/apple_icon.png';
import chalobharatlogo from '../../assets/chalobharatdekhe.png'

import religiousPlacesIcon from '../../assets/religious-places-icon.png';
import historicalPlacesIcon from '../../assets/historical-places-icon.png';
import heritagePlacesIcon from '../../assets/heritage-places-icon.png';
import hillStationIcon from '../../assets/hill-station-icon.png';

import nubraValley from '../../assets/nubra-valley.jpg';
import omkareshwarMamteshwarJyotirlingaTemple from '../../assets/omkareshwar-mamteshwar-jyotirlinga-temple .jpg';
import colvaBeach from '../../assets/colva-beach.jpg';
import fatehpursikri from '../../assets/fatehpur-sikri.jpg';

import badrinath from '../../assets/badrinath.jpg';
import charminar from '../../assets/charminar.jpg';
import taj_mahal from '../../assets/taj_mahal.jpg';
import srinagar from '../../assets/srinagar.jpg';
import videoPath from '../../assets/mobile_view.mp4'
import '../../App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Info from '../../Info';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
const Home = () => {

  const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.chalobharatdekhen.app', '_blank');
  };

  const openAppStore = () => {
    window.open('https://apps.apple.com/in/app/bharat-dekhen/id6478861610', '_blank');
  };
  
  const cards = [
    {
      icon: religiousPlacesIcon,
      name: 'Badrinath Temple',
      title: "Religious Places",
      description: "The Badrinath Temple also known as the Badrinarayan Temple is located in Chamoli district of Uttarakhand's Badrinath town, is one of the Char Dhams. There are four pilgrim-destinations namely Yamunotri, Gangotri, Kedarnath, and Badrinath, collectively known as Char Dham. These pilgrimage centers draw a large number of pilgrims each year, thus becoming the most important hubs of religious travel in the whole of Northern India. Badrinath is located at an elevation of around 3,100 m. Located in the Garhwal Himalayas, on the banks of the Alaknanda River, this sacred town lies between Nar and Narayana mountain ranges. The temple is believed to have been established by sage Adi Shankaracharya in the 8th century. With Lord Vishnu as its presiding deity, the temple remains open for six months in a year. In winter it becomes inaccessible due to heavy snowfall.",
      imageUrl: [badrinath]
    },
    {
      icon: historicalPlacesIcon,
      name: 'Charminar',
      title: "Historical Places",
      description: "Situated in the heart of the old city of Hyderabad, the Charminar (Urdu ‘Char’ meaning Four and ‘Minar’ meaning Tower) is one of the most recognized monuments in India. It was believed that Muhammad Quli Qutb Shah built the Charminar in 1591 to honor Allah. However, in reality this historical tourist place in India was built to signify the end of plague in the city. Legend has it that there is a secret tunnel from the monument to Golconda fort but it hasn’t been discovered till date. Each minaret of this monument has four stories and every arch has one clock.",
      imageUrl: [charminar]
    },
    {
      icon: heritagePlacesIcon,
      name: 'Taj Mahal',
      title: "UNESCO Heritage Sites",
      description: "The Taj Mahal is an ivory-white marble mausoleum on the south bank of the Yamuna River in the city of Agra. It was commissioned in 1632 by the Mughal emperor, Shah Jahan (reigned from 1628 to 1658), to house the tomb of his favourite wife, Mumtaz Mahal. The tomb is the centerpiece of a 17-hectare (42-acre) complex, which includes a mosque and a guest house, and is set in formal gardens bounded on three sides by a crenelated wall. The Taj Mahal was designated as a UNESCO World Heritage Site in 1983 for being “the jewel of Muslim art in India and one of the universally admired masterpieces of the world’s heritage” declared a winner of the New 7 Wonders of the World (2000–2007) initiative.",
      imageUrl: [taj_mahal]
    },
    {
      icon: hillStationIcon,
      name: 'Srinagar',
      title: "Hill Stations",
      description: "A place of splendid natural beauty, historic gardens and distinctive mosques, Srinagar is without a doubt one of best hill stations in North India. Get enchanted by the beautiful Nishat and Shalimar Bagh, the botanical gardens during the popular Tulip festival and Badamwari Garden to see the almond trees in full bloom. Make a visit to the tranquil Shankaracharya Mandir, the beautiful Khanqah Shah-i-Hamadan Mosque and Pari Mahal. And finally, spend a day exploring life on Dal Lake and Nigeen lake. It is among the coldest hill stations in India during winter.",
      imageUrl: [srinagar]
    }
  ];

  const cards2 = [
    {
      name: 'Nubra Valley',
      title: "Religious Places",
      description: "A tri-armed valley that is separated by Nubra (Siachan) River and Shyok River, Nubra Valley in Ladakh is popular for its cold desert and picturesque landscape. The valley is connected to Leh with Khardung La, one of the highest motorable passes in the world. Nubra valley is amongst the top tourist attractions in Ladakh and its Diskit Monastery and Hunder Village are a must-visit.",
      imageUrl: [nubraValley]
    },
    {
      name: 'Omkareshwar Mamteshwar Jyotirlinga Temple',
      title: "Historical Places",
      description: "Omkareshwar Temple located on an island called Shivapuri in the Narmada River in Mandhata nearby Khandwa city in Madhya Pradesh. Mahakaleshwar is located on the upper top of the temple. The word Omkareshwar means “Lord of Omkara'' or the Lord of the Om Sound! There are two main temples of Lord Shiva here one to Omkeshwar located on the island and another one to Mahakaleshwar (Amaleshwar) which means ‘Immortal Lord’ located on the south bank of Narmada River on the mainland. Here Lingam is made of sand and clay",
      imageUrl: [omkareshwarMamteshwarJyotirlingaTemple]
    },
    {
      name: 'Colva Beach',
      title: "UNESCO Heritage Sites",
      description: "Colva beach stretches for around 2.4 km, part of a beach consisting of about 25 km of powder white sand, lined along its shore by coconut palms, and extending as far as Bogmalo Beach to the north and Cabo de Rama Beach to the South Goa’s coastline. Colva is now a famous tourist destination, enjoyed for its beach. The tourist industry is well developed with many budget hotels, guest houses, beach shacks, food stalls, small restaurants and pubs and bars, although these developments haven’t extended in any large degree to the nightlife. The beaches are constantly monitored by lifeguards and the swimming areas flagged with colored flags accordingly. Being one of the famous beaches of Goa, this beach is highly crowded most of the time of the year. With a majority of its visitors being domestic Indian visitors, this beach is mostly neglected by foreigners.",
      imageUrl: [colvaBeach]
    },
    {
      name: 'Fatehpur Sikri',
      title: "Hill Stations",
      description: "Fatehpur Sikri is located in Agra District in the State of Uttar Pradesh. It was listed as a UNESCO site in 1986. It was constructed southeast of an artificial lake, on the sloping levels of the outcrops of the Vindhyan hill ranges. Known as the “city of victory”, it was made capital by the Mughal emperor Akbar (r. 1556-1605 CE) and constructed between 1571 and 1573. Fatehpur Sikri was the first planned city of the Mughals to be marked by magnificent administrative, residential, and religious buildings comprising palaces, public buildings, mosques, and living areas for the court, the army, the servants of the king and an entire city. Upon moving the capital to Lahore in 1585, Fatehpur Sikri remained as an area for temporary visits by the Mughal emperors.",
      imageUrl: [fatehpursikri]
    }
  ];

  const options = cards2.map((card, index) => ({
    value: index,
    label: card.name
  }));

  const [activeTab, setActiveTab] = useState(0);
  const [activeSelection, setActiveSelection] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleSelectChange = (selectedOption) => {
    setActiveSelection(selectedOption.value);
  };


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <>
      <div className='container'>
        <div className='logoMainView'>
          <Link to="/">
            <img src={chalobharatlogo} className="App-logo1" alt="logo" />
          </Link>

          <div>
            <div className='appView'>
              <p>Click to <br></br>download the app</p>
            </div>

            <div className="image-row">
              <div className="image-container" onClick={openAppStore}>
                <img src={applelogo} className="Android-logo" alt="Image 1" />
              </div>
              <div className="image-container" onClick={openPlayStore}>
                <img src={playStorelogo} className="Android-logo" alt="Image 2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className='col-lg-5 order-lg-1'>
            <div className='player-wrapper'>
              <video width="100%" height="100%" playsInline autoPlay loop muted preload="auto">
                <source src={videoPath} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="card-body">
              <p className="card-text1">
                Explore the vibrant tapestry of India with our Chalo Bharat Dekhen app. Immerse yourself in rich cultural insights, discover iconic landmarks like the Taj Mahal, and navigate bustling markets. Indulge in diverse cuisine recommendations, from spicy street food to fine dining. Plan seamless itineraries with detailed maps, local tips, and off-the-beaten-path gems. Uncover the magic of India, from the Himalayas to serene beaches. Your passport to an unforgettable journey awaits in the palm of your hand.
              </p>
            </div>
          </div>


        </div>
      </div>
      <div className="container mt-3 mt-lg-0 category-tab">
        <div className='section-title'>
          Search by Category
        </div>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='tabs'>
              {cards.map((card, index) => (
                <div className={`tab ${index === activeTab ? 'active' : ''}`} key={index} onClick={() => handleTabClick(index)}>
                  <img src={card.icon} />
                  <p>{card.title}</p>
                </div>
              ))}
            </div>

            <Slider {...settings}>
              {cards[activeTab].imageUrl.map((image, imgIndex) => (
                <>
                  <img src={image} alt={`Image ${imgIndex + 1}`} key={`Cat${imgIndex + 1}`} />
                </>
              ))}
            </Slider>
          </div>
          <div className='col-lg-6 offset-lg-1'>
            <div className='tab-content'>
              <h2>{cards[activeTab].name}</h2>
              <p>{cards[activeTab].description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container category-tab mt-3 mt-lg-5 pt-0 pt-lg-5 mb-5">
        <div className='section-title'>
          Search by Name
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='select-dropdown d-lg-none'>
              <Select
                value={options.find(option => option.value === activeSelection)}

                onChange={handleSelectChange}
                options={options}
                placeholder="Enter your destination ..."
              />
            </div>
            <div className='tab-content pt-0'>
              <h2>{cards2[activeSelection].name}</h2>
              <p>{cards2[activeSelection].description}</p>
            </div>
          </div>
          <div className='col-lg-5 offset-lg-1'>
            <div className='select-dropdown d-none d-lg-block'>
              <Select
                value={options.find(option => option.value === activeSelection)}
                onChange={handleSelectChange}
                options={options}
                placeholder="Enter your destination ..."
              />
            </div>

            <Slider {...settings}>
              {cards2[activeSelection].imageUrl.map((image, imgIndex) => (
                <>
                  <img src={image} alt={`Image ${imgIndex + 1}`} key={`Name${imgIndex + 1}`} />
                </>
              ))}
            </Slider>

          </div>
        </div>
      </div>
      <Info />
    </>

  );
}

export default Home;
