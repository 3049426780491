import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LoadTop from "../LoadTop";
import Home from "../pages/home";
import Policy from "../pages/privacy";
import Terms from "../pages/terms";
import UserManual from "../pages/user-manual";
const App = () => {
  return (
    <div className="App">
      <Router>
      <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/privacy-policy" component={Policy} />
          <Route path="/terms-and-conditions" component={Terms} />
          <Route path="/user-manual" component={UserManual} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
